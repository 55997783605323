import { useLayoutEffect, useEffect, useState } from "react";
import detectBrowserLanguage from "detect-browser-language";
import $ from "jquery";
import profile1 from "../assets/img/profile-01.png";
import profile2 from "../assets/img/profile-02.png";
import profile3 from "../assets/img/profile-03.png";
import profile4 from "../assets/img/profile-04.png";
import profile5 from "../assets/img/profile-05.png";

import discovery1 from "../assets/img/discovery-01.png";
import discovery2 from "../assets/img/discovery-02.png";
import discovery3 from "../assets/img/discovery-03.png";
import discovery4 from "../assets/img/discovery-04.png";

import connect1 from "../assets/img/connect-01.png";
import connect2 from "../assets/img/connect-02.png";
import connect3 from "../assets/img/connect-03.png";

import share1 from "../assets/img/share-01.png";
import share2 from "../assets/img/share-02.png";
import share3 from "../assets/img/share-03.png";
import share4 from "../assets/img/share-04.png";

import img1 from "../assets/img/profile-01.png";
import img2 from "../assets/img/profile-02.png";
import img3 from "../assets/img/profile-03.png";
import img4 from "../assets/img/profile-04.png";

import spainFlag from "../assets/img/spain-flag.png";
import usaFlag from "../assets/img/usa-flag.png";


import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import tEn from "../translater/en/translater.json";
import tEs from "../translater/es/translater.json";

const Home = () => {
  console.log("tEs : ", tEs.home);
  const [switchLang, setSwitchLang] = useState(false);
  // useLayoutEffect(() => {
  //   $("#nav-icon").click(function () {
  //     $(this).toggleClass("open");
  //     $(".navList").toggleClass("active");
  //   });
  // });

  const switchLangEng = () => {
    setSwitchLang(false);
  };
  const switchLangSpn = () => {
    setSwitchLang(true);
  };

  const [issOpen, setIsOpen] = useState(false);
  useLayoutEffect(() => {
    $("#scrollUp").click(function () {
      $(this).toggleClass("open");
      $(".formSection").toggleClass("active");
    });
  });

  const toggleMenu = () => {
    if (issOpen) {
      $("#nav-icon").removeClass("open");
      $("#navbarContent").removeClass("active");
    } else {
      $("#nav-icon").addClass("open");
      $("#navbarContent").addClass("active");
    }
    setIsOpen(!issOpen);
  }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: tEn,
        },
        es: {
          translation: tEs,
        },
      },
      // lng: "en",
      // fallbackLng: "en",
      // interpolation: {
      //   escapeValue: false,
      // },
    });

  let swt = false;
  const { t } = useTranslation();

  useEffect(() => {
    const defLang = detectBrowserLanguage();
    console.log("defLang : ", defLang);
    if (defLang.split("-")[0] === "en") {
      setSwitchLang(false);
    } else if (defLang.split("-")[0] === "es") {
      setSwitchLang(true);
    } else {
      setSwitchLang(false);
    }
    console.log("defLang : ", defLang.split("-")[0]);
  }, []);

  return (
    <>
      <div class="header">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div class="col-md-4 col-4">
              <div class="logoWrap">
                <a href="/">el3ment.</a>
              </div>
            </div>
            <div class="col-md-8 col-8">
              <div class="navbar-wrap">
                <div type="button"
                    data-toggle="collapse"
                    data-target="#navbarContent"
                    aria-controls="navbars"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    id="nav-icon"
                    onClick={() => { toggleMenu() }}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul class="navList" id="navbarContent">
                  
                  <li>
                    <a href="https://www.el3mentapp.com/">
                      {t(switchLang ? tEs.home : tEn.home)}
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@el3ment">
                      {" "}
                      {t(switchLang ? tEs.about : tEn.about)}
                    </a>
                  </li>
          
                  <li class="getAppBtn">
                    <a href="https://apps.apple.com/us/app/el3ment/id1575590744">
                      {t(switchLang ? tEs.gettheapp : tEn.gettheapp)}
                    </a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mainContent">
        <div class="bannerWrap">
          <div class="container">
            <div class="col-md-12">
              <div class="bannnerTitlWrap">
                <p>{t(switchLang ? tEs.appguides : tEn.appguides)}</p>
                <h1>{t(switchLang ? tEs.gtmoe : tEn.gtmoe)}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="tabNavWrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-10 col-md-12">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                    >
                      {t(switchLang ? tEs.profile : tEn.profile)}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                    >
                      {t(switchLang ? tEs.discovery : tEn.discovery)}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#tabs-3"
                      role="tab"
                    >
                      {t(switchLang ? tEs.connect : tEn.connect)}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#tabs-4"
                      role="tab"
                    >
                      {t(switchLang ? tEs.share_music : tEn.share_music)}
                    </a>
                  </li>
                  {/*<li class="nav-item">
                          <a class="nav-link" data-toggle="tab" href="#tabs-5" role="tab">YOUR ACCOUNT</a>
                        </li>*/}
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tabs-1" role="tabpanel">
                    <div class="row align-items-center">
                    <div class="dataWrapper">
                      <h2>
                                {t(
                                  switchLang
                                    ? tEs.profile_heading
                                    : tEn.profile_heading
                                )}
                        </h2>
                        </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang
                                ? tEs.avtar_n_banner
                                : tEn.avtar_n_banner
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.add_avt_n_bnr
                                : tEn.add_avt_n_bnr
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={profile1} class="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={profile2} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {" "}
                            {t(
                              switchLang
                                ? tEs.genres_n_sound
                                : tEn.genres_n_sound
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.select_rele_gen
                                : tEn.select_rele_gen
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={profile3} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(switchLang ? tEs.music_samp : tEn.music_samp)}
                          </h2>
                          <p>
                            {t(
                              switchLang ? tEs.add_samp_work : tEn.add_samp_work
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={profile4} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(switchLang ? tEs.bio_n_links : tEn.bio_n_links)}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.add_bio_up_two
                                : tEn.add_bio_up_two
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={profile5} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang
                                ? tEs.profile_setting
                                : tEn.profile_setting
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.update_your_profile
                                : tEn.update_your_profile
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-2" role="tabpanel">
                  <div class="row align-items-center">
                    <div class="dataWrapper">
                      <h2>
                                {t(
                                  switchLang
                                    ? tEs.discovery_heading
                                    : tEn.discovery_heading
                                )}
                        </h2>
                        </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2> {t(switchLang ? tEs.map : tEn.map)}</h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.music_maker_ww
                                : tEn.music_maker_ww
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={discovery1} class="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={discovery2} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>{t(switchLang ? tEs.filters : tEn.filters)}</h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.apply_filters_specify
                                : tEn.apply_filters_specify
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={discovery3} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang ? tEs.swipe_listen : tEn.swipe_listen
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.swipe_listen_sound
                                : tEn.swipe_listen_sound
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={discovery4} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang
                                ? tEs.discovery_panel
                                : tEn.discovery_panel
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.swipe_up_browse_group
                                : tEn.swipe_up_browse_group
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-3" role="tabpanel">
                  <div class="row align-items-center">
                    <div class="dataWrapper">
                      <h2>
                                {t(
                                  switchLang
                                    ? tEs.connect_heading
                                    : tEn.connect_heading
                                )}
                        </h2>
                        </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang
                                ? tEs.sending_requests
                                : tEn.sending_requests
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.reqest_to_connect
                                : tEn.reqest_to_connect
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={connect1} class="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={connect2} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(switchLang ? tEs.translate : tEn.translate)}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.toggle_on_to_translate
                                : tEn.toggle_on_to_translate
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={connect3} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang ? tEs.attach_Sample : tEn.attach_Sample
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.attach_sample_message
                                : tEn.attach_sample_message1
                            )}
                          </p>
                          <br></br>
                          <p>
                            {t(
                              switchLang
                                ? tEs.attach_sample_message
                                : tEn.attach_sample_message2
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-4" role="tabpanel">
                  <div class="row align-items-center">
                    <div class="dataWrapper">
                      <h2>
                                {t(
                                  switchLang
                                    ? tEs.share_heading
                                    : tEn.share_heading
                                )}
                        </h2>
                        </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(switchLang ? tEs.get_started : tEn.get_started)}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.add_sample_your_music
                                : tEn.add_sample_your_music1
                            )}
                          </p>
                          <br></br>
                          <p>
                            {t(
                              switchLang
                                ? tEs.add_sample_your_music
                                : tEn.add_sample_your_music2
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={share1} class="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={share2} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>{t(switchLang ? tEs.upload : tEn.upload)}</h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.music_must_be_stored
                                : tEn.music_must_be_stored1
                            )}
                          </p>
                          <p>
                            {t(
                              switchLang
                                ? tEs.music_must_be_stored
                                : tEn.music_must_be_stored2
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={share3} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang
                                ? tEs.clip_n_customize
                                : tEn.clip_n_customize
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.select_twenty_snippet
                                : tEn.select_twenty_snippet
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={share4} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h2>
                            {t(
                              switchLang ? tEs.collaborators : tEn.collaborators
                            )}
                          </h2>
                          <p>
                            {t(
                              switchLang
                                ? tEs.select_role_played
                                : tEn.select_role_played
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-4" role="tabpanel">
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={img1} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h6>{t(switchLang ? tEs.intro : tEn.intro)}</h6>
                          <h2>
                            {t(
                              switchLang
                                ? tEs.fill_profile_discovered
                                : tEn.fill_profile_discovered
                            )}
                          </h2>
                          <p>
                            Next section hahah..iwriywheryhw iwurhw weirhwe
                            weiurhwe weiurhwe wuierhw w eru h wiuehr wuethuo
                            weuorhw oerh.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={img2} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h6>STEP 1</h6>
                          <h2>Let people know it’s you</h2>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={img3} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h6>STEP 2</h6>
                          <h2>Tag yourself with Genres and Sounds</h2>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center flex-row-reverse">
                      <div class="col-md-6">
                        <div class="imgWrapper">
                          <img src={img4} class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="dataWrapper">
                          <h6>STEP 3</h6>
                          <h2>Add your Music Samples</h2>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
