import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

function RedirectPage() {
    const { id } = useParams();
    useEffect(() => {
        if (id) {
          const redirectUrl = `el3ment://profiles/${id}`; 
          const fallbackUrl = 'https://linktr.ee/el3mentapp';
      
          window.location.href = redirectUrl;
      
          setTimeout(() => {
            if (!document.hidden) {
              window.location.href = fallbackUrl;
            }
          }, 2000);
        }
      }, [id]);
    
      return (
        <div>
          <p style={{color: "black", textAlign: "center"}}>
            <a href="https://linktr.ee/el3mentapp">Click here</a> to download el3ment app application.
          </p>
        </div>
      );
}

export default RedirectPage;
