
import './App.css';
import Home from './component/Home';
import {Switch, Route} from 'react-router-dom'
import RedirectPage from './component/RedirectPage';


function App() {
  return (
    <>
    <Switch>
      <Route exact path="/">
        <Home/>
      </Route>
      <Route exact path="/redirect/:id">
        <RedirectPage />
      </Route>
    </Switch>    
    </>
  );
}

export default App;
